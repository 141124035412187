<template>
    <v-sheet style="position: relative;" color="background" class="fill-height pa-2 d-flex align-stretch">
        <v-card elevation="11" color="panel" class="w-100 d-flex flex-column">
            <v-toolbar color="toolbar" tile flat dense class="flex-grow-0 remove-end-padding">
                <v-toolbar-title>Live Campaigns</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon @click="showOptions = true">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider color="white"></v-divider>
            <v-data-table
                class="flex-grow-1 panel full-height"
                :headers="headers"
                :mobile-breakpoint="mobileBreakpoint"
                :fixed-header="!labelsInRows"
                :hide-default-header="labelsInRows"
                hide-default-footer
                disable-sort
                :loading="loading"
                loading-text="Loading Live Campaigns..."
                no-data-text="No Live Campaigns Detected"
                :items="items"
            >
                <template v-slot:item.campaign="{ item }">
                    <span v-text="truncate(item.campaign, 30)" :title="item.campaign"></span>
                </template>
                <template v-slot:item.publisher="{ item }">
                    <span v-text="truncate(item.publisher, 20)" :title="item.publisher"></span>
                </template>
                <template v-slot:item.type="{ item }">
                    <v-icon v-text="item.typeIcon"></v-icon>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="showOptions" max-width="500">
            <v-card color="panel">
                <v-toolbar color="toolbar" class="remove-end-padding" dense>
                    <v-toolbar-title>Options</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon @click="showOptions = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pa-3">
                    <label class="d-block">Display Columns:</label>
                    <checklist v-model="showHeaders" :items="rawHeaders" :autoable="true" label="Column" class="panel" fixed-header height="200"></checklist>
                    <!-- <v-switch v-model="labelsInRows" label="Show Labels in Rows"></v-switch> -->
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
import checklist from '../../components/general/checklist';
import shortid from 'shortid';
import moment from 'moment-timezone';
import numeral from 'numeral';
import truncate from 'truncate';

export default {
    components: {
        checklist,
    },
    data: () => ({
        showHeaders: true,
        showOptions: false,
        labelsInRows: false,
        loaded: {
            campaigns: false,
        },
        requests: {},
        timeouts: {},
        camps: [],
        lu: 0,
        truncate,
    }),
    computed: {
        rawHeaders() {
            return [
                {
                    text: 'Publisher',
                    value: 'publisher',
                },
                {
                    text: 'Type',
                    value: 'type',
                },
                {
                    text: 'Campaign',
                    value: 'campaign',
                },
                {
                    text: 'CID',
                    value: 'cid',
                },
                {
                    text: 'Countries',
                    value: 'countries',
                },
                {
                    text: 'Spend',
                    value: 'spend',
                },
                {
                    text: 'Impressions',
                    value: 'impressions',
                },
                {
                    text: 'Clicks',
                    value: 'clicks',
                },
                {
                    text: 'Leads',
                    value: 'leads',
                },
                {
                    text: 'FTDs',
                    value: 'ftds',
                },
                {
                    text: 'CTR',
                    value: 'ctr',
                },
                {
                    text: 'CPL',
                    value: 'cpl',
                },
                {
                    text: 'CPA',
                    value: 'cpa',
                },
                {
                    text: 'CR',
                    value: 'cr',
                },
                {
                    text: 'Started',
                    value: 'started_at',
                },
                {
                    text: 'Last Activity',
                    value: 'last_activity_at',
                },
            ];
        },
        headers() {
            if (0 == this.showHeaders.length) {
                return [];
            }
            return [
                // {
                //     text: '',
                //     value: '__actions__',
                // }
            ].concat(this.rawHeaders.filter((v) => {
                return (true == this.showHeaders || this.showHeaders.indexOf(v.value) > -1);
            }))
        },
        mobileBreakpoint() {
            return this.labelsInRows ? 999999 : 0;
        },
        loading() {
            let ret = false;
            for (let key in this.loaded) {
                if (false == this.loaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        items: {
            set(val) {
                val;
            },
            get() {
                this.lu;
                const tz = (this.$auth && this.$auth.info && this.$auth.info.timezone) ? this.$auth.info.timezone : 'UTC';
                const cr = JSON.parse(JSON.stringify([...this.camps]));
                return cr.map((v) => {
                    v.spend = this.formatCurrency(v.spend);
                    v.impressions = this.formatInteger(v.impressions);
                    v.clicks = this.formatInteger(v.clicks);
                    v.leads = this.formatInteger(v.leads);
                    v.ftds = this.formatInteger(v.ftds);
                    v.ctr = this.formatPercent(v.ctr * 100);
                    v.cpl = this.formatCurrency(v.cpl);
                    v.cpa = this.formatCurrency(v.cpa);
                    v.cr = this.formatPercent(v.cr * 100);
                    v.started_at = moment(v.started_at).tz(tz).format('lll');
                    v.last_activity_at = moment(v.last_activity_at).tz(tz).format('lll');
                    // v.countries = v.countries.join(', ');
                    v.typeIcon = ('sms' == v.type) ? 'mdi-cellphone-message' : 'mdi-newspaper';
                    return v;
                })
            }
        }
    },
    methods: {
        async loadAll() {
            await Promise.all([
                this.getLiveCampaigns(),
            ]);
        },
        async getLiveCampaigns() {
            if (!this.$io.connected) {
                return false;
            }
            this.loaded.campaigns = false;
            const id = shortid.generate();
            this.requests.loadBoards = id;
            try {
                const res = await this.$io.request('App/Models/Campaign', 'getMyLiveCampaigns', [], 60000);
                if (this.requests.loadBoards == id) {
                    // this.settings.options.campaigns = res.campaigns;
                    this.handleLiveCamps(res, true);
                    this.loaded.campaigns = true;
                }
            }
            catch (error) {
                if (this.requests.loadBoards == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.campaigns = true;
                }
            }
        },
        async handleLiveCamps(res, fromReq) {
            // livecamps
            if (!fromReq) {
                clearTimeout(this.timeouts.campRefresh)
                this.loaded.campaigns = false;
            }
            // console.log(res.campaigns);
            while (this.camps.length > 0) {
                this.camps.splice(0, 1);
            }
            for (let i = 0; i < res.campaigns.length; i++) {
                const camp = res.campaigns[i];
                this.camps.push(camp)
            }
            this.$nextTick(() => {
                this.lu = res.timestamp;
            })
            if (!fromReq) {
                this.timeouts.campRefresh = setTimeout(() => {
                    this.loaded.campaigns = true;
                }, 250)
            }
        },
        formatCurrency(value) {
            if (null == value) {
                return '∞';
            }
            return this.$currency.display(value);
        },
        formatInteger(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return n.format('0,0');
        },
        formatPercent(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return `${n.format('0,0.00')}%`;
        },
    },
    mounted() {
        this.loadAll();
        this.$io.$on('connected', this.loadAll);
        this.$io.$on('livecamps', this.handleLiveCamps);
    },
    beforeDestroy() {
        this.$io.$off('connected', this.loadAll);
        this.$io.$off('livecamps', this.handleLiveCamps);
        for (let i in this.timeouts) {
            clearTimeout(this.timeouts[i]);
        }
    },
}
</script>