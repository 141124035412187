<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
         <v-container class="h-100 d-flex w-100 align-stretch" fluid>
            <v-row class="align-stretch justify-center">
                <v-col cols="12">
                    <v-card color="panel" class="h-100 mb-0">
                        <v-toolbar color="toolbar" dense class="remove-padding">
                            <v-toolbar-title class="mx-3">My Chatbots</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn icon :disabled="!$io.connected" :loading="loading" @click="load">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                                <v-btn icon @click="show.add = true">
                                    <v-icon>mdi-message-plus-outline</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-divider color="white"></v-divider>
                        <v-data-table
                            class="panel"
                            :headers="headers"
                            :items="mine"
                            hide-default-footer
                            :items-per-page="mine.length"
                            :expanded.sync="expanded"
                            show-expand
                        >
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" class="px-0">
                                    <div>
                                        <dnc
                                            :report.sync="item.dnc_hourly_report_times"
                                            :lead.sync="item.dnc_lead_times"
                                            :ftd.sync="item.dnc_ftd_times"
                                            @change="updateChatbot({dnc_hourly_report_times: item.dnc_hourly_report_times,dnc_lead_times: item.dnc_lead_times,dnc_ftd_times: item.dnc_ftd_times}, item)"
                                        >
                                        </dnc>
                                    </div>
                                </td>
                            </template>
                            <template v-slot:item.is_enabled="{ item }">
                                <div class="d-flex w-100 justify-center align-start">
                                    <v-switch style="position: relative; top: -10px;" v-model="item.is_enabled" :loading="loading" @change="updateChatbot({is_enabled: item.is_enabled}, item)" hide-details></v-switch>
                                </div>
                            </template>

                            <template v-slot:item.is_hourly_report="{ item }">
                                <div class="d-flex w-100 justify-center align-start">
                                    <v-switch style="position: relative; top: -10px;" v-model="item.is_hourly_report" :loading="loading" @change="updateChatbot({is_hourly_report: item.is_hourly_report}, item)" hide-details></v-switch>
                                </div>
                            </template>
                            <template v-slot:item.is_notify_lead="{ item }">
                                <div class="d-flex w-100 justify-center align-start">
                                    <v-switch style="position: relative; top: -10px;" v-model="item.is_notify_lead" :loading="loading" @change="updateChatbot({is_notify_lead: item.is_notify_lead}, item)" hide-details></v-switch>
                                </div>
                            </template>
                            <template v-slot:item.is_notify_ftd="{ item }">
                                <div class="d-flex w-100 justify-center align-start">
                                    <v-switch style="position: relative; top: -10px;" v-model="item.is_notify_ftd" :loading="loading" @change="updateChatbot({is_notify_ftd: item.is_notify_ftd}, item)" hide-details></v-switch>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate size="64" color="secondary"></v-progress-circular>
        </v-overlay>
        <v-dialog max-width="500" v-model="show.add">
            <v-card color="panel">
                <v-toolbar dense color="toolbar" class="remove-end-padding">
                    <v-toolbar-title>Connect to a Chatbot</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon @click="show.add = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-stepper :value="e1">
                    <v-stepper-header class="panel">
                        <v-stepper-step :complete="e1 > 1" step="1">Choose Chatbot</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 2" step="2">Start Chat</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items class="panel">
                        <v-stepper-content step="1" class="panel">
                            <v-card-text>
                                <v-autocomplete :items="available" v-model="add.selectedChatbot" :loading="add.loading" :disabled="add.loading" clearable></v-autocomplete>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" :loading="add.loading" :disabled="null == add.selectedChatbot" @click="chooseBot">Continue</v-btn>
                            </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="2" class="panel">
                            <v-list v-if="addBot" class="panel">
                                <v-list-item two-line>
                                    <v-list-item-content v-if="add.qrcode && $vuetify.breakpoint.mdAndUp" class="flex-shrink-1">
                                        <v-img :src="add.qrcode" max-height="100" max-width="100" aspect-ratio="1" contain></v-img>
                                    </v-list-item-content>
                                    <v-list-item-icon v-else>
                                        <v-icon>mdi-cursor-default-click</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Open the following link:</v-list-item-subtitle>
                                        <v-list-item-title>
                                            <a :href="addBot.url" target="_blank" v-text="addBot.driver"></a>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line v-if="'text' == addBot.type">
                                    <v-list-item-icon>
                                        <v-icon>mdi-comment-account-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Search for the bot user:</v-list-item-subtitle>
                                        <v-list-item-title>
                                            <code v-text="addBot.bot"></code>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="'text' == addBot.type">
                                    <v-list-item-icon>
                                        <v-icon>mdi-send</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Send the following message:</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-textarea readonly :value="cmd" v-if="addBot && 'text' == addBot.type" outlined label="Message to Send" append-icon="mdi-content-copy" @click:append="copyText(cmd)"></v-textarea>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" :loading="add.loading" :disabled="null == add.selectedChatbot" @click="clearBot">Done</v-btn>
                            </v-card-actions>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
import dnc from '../../components/chatbots/dnc'
import shortid from 'shortid';
import qrcode from 'qrcode';
export default {
    components: {
        dnc,
    },
    data: () => ({
        loaded: {
            available: false,
            mine: false,
        },
        available: [],
        mine: [],
        requests: {},
        show: {
            add: false,
        },
        add: {
            loading: false,
            selectedChatbot: null,
            chatbot: null,
            qr: null,
        },
        expanded: [],
    }),
    computed: {
        loading() {
            let ret = false;
            for (let key in this.loaded) {
                if (false == this.loaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        e1() {
            return (null == this.add.chatbot) ? 1 : 2;
        },
        addBot() {
            return this.getItemPart(this.add.chatbot, this.available, 'login');
        },
        cmd() {
            if (!this.addBot) {
                return '';
            }
            return `${this.addBot.cmdPrefix}login ${this.addBot.token}`;
        },
        headers() {
            return [
                // {
                //     text: 'Actions',
                //     value: '__actions__',
                //     align: 'start',
                //     sortable: false,
                //     filterable: false,
                //     groupable: false,
                //     divider: false,
                // },
                { text: '', value: 'data-table-expand' },
                {
                    text: 'Name',
                    value: 'name',
                    align: 'start',
                    sortable: true,
                    filterable: false,
                    groupable: false,
                    divider: false,
                },
                {
                    text: 'Driver',
                    value: 'driver',
                    align: 'start',
                    sortable: true,
                    filterable: false,
                    groupable: false,
                    divider: false,
                },
                {
                    text: 'Enabled',
                    value: 'is_enabled',
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    groupable: false,
                    divider: false,
                },
                {
                    text: 'Send Hourly Report',
                    value: 'is_hourly_report',
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    groupable: false,
                    divider: false,
                },
                {
                    text: 'Notify on Lead Conversion',
                    value: 'is_notify_lead',
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    groupable: false,
                    divider: false,
                },
                {
                    text: 'Notify on FTD',
                    value: 'is_notify_ftd',
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    groupable: false,
                    divider: false,
                },
            ]
        }
    },
    methods: {
        async load() {
            this.loadAvailable();
            this.loadMine();
        },
        async loadAvailable() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            this.loaded.available = false;
            const id = shortid.generate();
            this.requests.loadAvailable = id;
            try {
                const res = await this.$io.request('App/Models/UserChatBot', 'getAvailableChatbots', [], 60000);
                if (this.requests.loadAvailable == id) {
                    this.available = res;
                    this.loaded.available = true;
                }
            }
            catch (error) {
                if (this.requests.loadAvailable == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.available = true;
                }
            }
        },
        async loadMine() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            this.loaded.mine = false;
            const id = shortid.generate();
            this.requests.loadMine = id;
            try {
                const res = await this.$io.request('App/Models/UserChatBot', 'getMyChatbots', [], 60000);
                if (this.requests.loadMine == id) {
                    this.mine = res;
                    this.loaded.mine = true;
                }
            }
            catch (error) {
                if (this.requests.loadMine == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.mine = true;
                }
            }
        },
        async chooseBot() {
            if (null == this.add.selectedChatbot) {
                return;
            }
            this.add.loading = true;
            const bot = this.getItemPart(this.add.selectedChatbot, this.available, 'login');
            try {
                this.add.qrcode = await qrcode.toDataURL(bot.url);
                this.add.chatbot = this.add.selectedChatbot;
            }
            catch (error) {
                console.warn(error);
            }
            this.add.loading = false;
        },
        clearBot() {
            this.add.loading = false;
            this.add.selectedChatbot = null;
            this.add.chatbot = null;
            this.add.qrcode = null;
            this.show.add = false;
        },
        onCopySuccess() {
            this.$PNotify.success({
                title: 'Copied to Clipboard'
            })
        },
        onCopyError() {
            this.$$PNotify.error({
                title: 'Failed to copy to clipboard'
            })
        },
        copyText(msg) {
            console.debug('copying', msg);
            this.$copyText(msg).then(this.onCopySuccess).catch(this.onCopyError);
        },
        async updateChatbot(updates, chatbot) {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            this.loaded.mine = false;
            const id = shortid.generate();
            this.requests.loadMine = id;
            try {
                await this.$io.request('App/Models/UserChatBot', 'updateMyChatbot', [chatbot.id, updates], 60000);
                if (this.requests.loadMine == id) {
                    await this.loadMine();
                }
            }
            catch (error) {
                if (this.requests.loadMine == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadMine();
                }
            }
        }
    },
    mounted() {
        this.$io.$on('connected', this.load);
        this.$io.$on('userchatbot:saved', this.loadMine);
        this.$io.$on('chatbot:saved', this.loadAvailable);
        this.load();
    },
    beforeDestroy() {
        this.$io.$off('connected', this.load);
        this.$io.$off('userchatbot:saved', this.loadMine);
        this.$io.$off('chatbot:saved', this.loadAvailable);
    },
}
</script>