<template>
    <v-sheet style="position: relative;" color="background" class="fill-height pa-2 d-flex align-stretch">
        <v-card elevation="11" color="panel" class="w-100 d-flex flex-column">
            <v-toolbar color="toolbar" tile flat dense class="flex-grow-0 remove-end-padding">
                <v-toolbar-title>Dialer Summary</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title>
                    <small>
                        <code :key="u" v-text="lud"></code>
                    </small>
                </v-toolbar-title>
                <v-toolbar-items>
                    <v-btn icon @click="showCamps = true" v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    <v-btn icon @click="showOptions = true">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider color="white"></v-divider>
            <v-sheet class="flex-grow-1 panel" id="summary-sheet">
                <v-navigation-drawer v-model="showCamps" :permanent="$vuetify.breakpoint.mdAndUp" bottom :absolute="$vuetify.breakpoint.mdAndUp" :fixed="$vuetify.breakpoint.smAndDown" color="panel" mobile-breakpoint="960" class="pb-3">
                    <template v-slot:prepend>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="title">
                                    Active Campaigns
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                    <v-list-item two-line v-for="(c) in listCampaigns" :key="c.key">
                        <v-list-item-content>
                            <v-list-item-title v-text="c.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="`${c.id} - ${c.dialer}`"></v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-file-phone-outline</v-icon><strong>Dialable:</strong>&nbsp;{{formatInteger(c.dialable)}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-phone-settings</v-icon><strong>Hopper:</strong>&nbsp;{{formatInteger(c.hopperSize)}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-face-agent</v-icon><strong>Agents:</strong>&nbsp;{{formatInteger(c.agents)}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-percent</v-icon><strong>Ratio:</strong>&nbsp;{{c.ratio}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-phone-outgoing</v-icon><strong>Dialing:</strong>&nbsp;{{formatInteger(c.dialing)}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-phone-alert</v-icon><strong>Waiting:</strong>&nbsp;{{formatInteger(c.waiting)}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-phone-remove</v-icon><strong>Drop Rate:</strong>&nbsp;{{c.dropRate}}%
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-phone-paused</v-icon><strong>Avg Wait Time:</strong>&nbsp;{{formatInteger(c.avgWaitTime)}}s
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon size="14" class="mr-1" style="position: relative; top: -2px;">mdi-phone-in-talk</v-icon><strong>Avg Call Time:</strong>&nbsp;{{formatInteger(c.avgTalkTime)}}s
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-navigation-drawer>
                <v-data-table
                    :loading="loading"
                    :dark="true"
                    :class="(showWaiting.length > 0) ? 'flex-grow-1 error darken-2 full-height' : 'flex-grow-1 panel full-height'"
                    :headers="waitingHeaders"
                    :mobile-breakpoint="0"
                    :fixed-header="true"
                    hide-default-footer
                    disable-sort
                    loading-text="Loading Pending Calls..."
                    no-data-text="No Pending Calls"
                    :items="showWaiting"
                    :items-per-page="showWaiting.length"
                    item-key="key"
                    v-if="showWaiting.length > 0 || $vuetify.breakpoint.mdAndUp"
                >
                </v-data-table>
                <v-divider></v-divider>
                <v-data-table
                    :loading="loading"
                    class="flex-grow-1 panel full-height no-header-mobile"
                    :headers="agentHeaders"
                    :fixed-header="$vuetify.breakpoint.mdAndUp"
                    hide-default-footer
                    disable-sort
                    loading-text="Loading Active Agents..."
                    no-data-text="No Active Agents"
                    :items="showAgents"
                    :items-per-page="showAgents.length"
                    item-key="key"
                    show-group-by
                    :dense="$vuetify.breakpoint.smAndDown"
                >
                    <template v-slot:item.status="{ item }">
                        <v-chip small v-bind="getStatusColor(item)">
                            <span class="font-weight-bold" v-text="item.status"></span>
                            <span class="ml-2" v-if="item.method.length > 0" v-text="item.method"></span>
                        </v-chip>
                    </template>
                </v-data-table>
            </v-sheet>
        </v-card>
        <v-dialog v-model="showOptions" max-width="500">
            <v-card color="panel">
                <v-toolbar color="toolbar" class="remove-end-padding" dense>
                    <v-toolbar-title>Options</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon @click="showOptions = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pa-3">
                    <label class="d-block">Campaigns:</label>
                    <checklist v-model="showCampaigns" :items="campaignOptions" :autoable="true" label="Column" class="panel" fixed-header height="200"></checklist>
                    <label class="d-block">Teams:</label>
                    <checklist v-model="showGroups" :items="groupOptions" :autoable="true" label="Column" class="panel" fixed-header height="200"></checklist>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
import checklist from '../../components/general/checklist';
import moment from 'moment-timezone';
import merge from 'lodash.merge';
import colors from 'vuetify/lib/util/colors'
export default {
    components: {
        checklist,
    },
    data: () => ({
        rawCampaigns: {},
        rawGroups: {},
        rawAgents: {},
        rawWaiting: {},
        lu: 0,
        u: 0,
        showGroups: true,
        showCampaigns: true,
        loading: true,
        showOptions: false,
        showCamps: false,
    }),
    computed: {
        lud() {
            this.u;
            const m = moment.unix(this.lu);
            return m.format('LTS');
        },
        campaigns() {
            this.lu;
            this.u;
            if (0 == Object.keys(this.rawCampaigns).length) {
                return [];
            }
            return Object.values(this.rawCampaigns);
        },
        groups() {
            this.lu;
            this.u;
            if (0 == Object.keys(this.rawGroups).length) {
                return [];
            }
            return Object.values(this.rawGroups);
        },
        campaignOptions() {
            const ret = new Set();
            this.campaigns.map((v) => {
                ret.add({
                    text: v.name,
                    value: v.key,
                })
            })
            return [...ret];
        },
        groupOptions() {
            const ret = new Set();
            this.groups.map((v) => {
                ret.add({
                    text: [v.name, v.dialer].join(' - '),
                    value: v.key,
                })
            })
            return [...ret];
        },
        agents() {
            this.lu;
            this.u;
            const ret = new Set();
            for (let di in this.rawAgents) {
                const das = this.rawAgents[di];
                for (let i = 0; i < das.length; i++) {
                    const a = das[i];
                    a.key = [di, a.session].join('-');
                    a.ckey = [di, a.campaign].join('-');
                    a.gkey = [di, a.group].join('-');
                    ret.add(a);
                }
            }
            return [...ret].sort(this.sortAgents);
        },
        waiting() {
            this.lu;
            this.u;
            const ret = new Set();
            for (let di in this.rawWaiting) {
                const das = this.rawWaiting[di];
                for (let i = 0; i < das.length; i++) {
                    const a = das[i];
                    a.key = [di, a.phone].join('-');
                    a.ckey = [di, a.campaign].join('-');
                    ret.add(a);
                }
            }
            return [...ret].sort(this.sortWaiting);
        },
        showAgents() {
            return this.agents.filter((v) => {
                // filter for groups
                return (this.showGroups == true || (Array.isArray(this.showGroups) && this.showGroups.indexOf(v.gkey) > -1));
            }).filter((v) => {
                // filter for campaigns
                return (this.showCampaigns == true || (Array.isArray(this.showCampaigns) && this.showCampaigns.indexOf(v.ckey) > -1));
            });
        },
        showWaiting() {
            return this.waiting.filter((v) => {
                // filter for campaigns
                return (this.showCampaigns == true || (Array.isArray(this.showCampaigns) && this.showCampaigns.indexOf(v.ckey) > -1));
            });
        },
        agentHeaders() {
            const ret = [
                {
                    text: 'Agent',
                    value: 'agent',
                    groupable: false,
                },
                {
                    text: 'Team',
                    value: 'group',
                    groupable: true,
                },
                {
                    text: 'Campaign',
                    value: 'campaign',
                    groupable: true,
                },
                {
                    text: 'Status',
                    value: 'status',
                    groupable: false,
                },
                {
                    text: 'Customer',
                    value: 'phone',
                    groupable: false,
                },
                {
                    text: 'Duration',
                    value: 'duration',
                    groupable: false,
                },
            ].filter((v) => {
                if (Array.isArray(this.showCampaigns) && 1 == this.showCampaigns.length && 'campaign' == v.value) {
                    return false;
                }
                if (Array.isArray(this.showGroups) && 1 == this.showGroups.length && 'group' == v.value) {
                    return false;
                }
                if (this.$vuetify.breakpoint.smAndDown && 'phone' == v.value) {
                    return false;
                }
                return true;
            });
            return ret;
        },
        waitingHeaders() {
            const ret = [
                {
                    text: 'Campaign',
                    value: 'campaign',
                    groupable: true,
                },
                {
                    text: 'Customer',
                    value: 'phone',
                    groupable: false,
                },
                {
                    text: 'Duration',
                    value: 'waiting',
                    groupable: false,
                },
            ];
            return ret;
        },
        listCampaigns() {
            return this.campaigns.filter((v) => {
                // filter by shown campaigns
                return (this.showCampaigns == true || Array.isArray(this.showCampaigns) && this.showCampaigns.indexOf(v.key) > -1);
            }).filter((v) => {
                v;
                return true;
                // return (v.agents > 0 || v.dialing > 0 || v.waiting > 0);
            })
        }
    },
    methods: {
        handleDialerReport({dialer, report}, timestamp) {
            this.lu = timestamp;
            dialer;
            const {campaigns, groups, agents, waiting} = report;
            // console.debug(`Dialer Report took ${processTime}ms for dialer ${dialer}`);
            // processTime;
            for (let i = 0; i < campaigns.length; i++) {
                const campaign = campaigns[i];
                const key = [dialer, campaign.id].join('-');
                this.rawCampaigns[key] = merge({}, campaign, {key});
            }
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                const key = [dialer, group.id].join('-');
                this.rawGroups[key] = merge({}, group, {key});
            }
            if ('undefined' == typeof this.rawAgents[`${dialer}`]) {
                this.rawAgents[`${dialer}`] = {};
            }
            this.rawAgents[`${dialer}`] = agents;
            if ('undefined' == typeof this.rawWaiting[`${dialer}`]) {
                this.rawWaiting[`${dialer}`] = {};
            }
            this.rawWaiting[`${dialer}`] = waiting;
            this.$nextTick(() => {
                this.u ++;
                this.loading = false;
            });
        },
        sortAgents(a, b) {
            const order = ['READY', 'QUEUE', 'DIAL', 'INCALL', 'DEAD', 'DISPO', 'PAUSED'];
            const ai = order.indexOf(a.status);
            const bi = order.indexOf(b.status);
            if (ai == bi) {
                const ad = moment.duration(a.duration);
                const bd = moment.duration(b.duration);
                return ad.valueOf() - bd.valueOf();
            }
            return ai - bi;
        },
        sortWaiting(a, b) {
            const ad = moment.duration(a.duration);
            const bd = moment.duration(b.duration);
            return ad.valueOf() - bd.valueOf();
        },
        getStatusColor(agent) {
            let base = colors.grey;
            let dark = true;
            let light = false;
            switch (agent.status) {
                case 'READY':
                    base = colors.cyan;
                    dark = false;
                    light = true;
                    break;
                case 'QUEUE':
                    base = colors.teal;
                    dark = false;
                    light = true;
                    break;
                case 'DIAL':
                    base = colors.teal;
                    dark = false;
                    light = true;
                    break;
                case 'INCALL':
                    base = colors.green;
                    dark = false;
                    light = true;
                    break;
                case 'DISPO':
                    base = colors.orange;
                    dark = false;
                    light = true;
                    break;
                case 'PAUSED':
                    base = colors.deepOrange;
                    dark = false;
                    light = true;
                    break;
            }
            const duration = this.parseDuration(agent.duration).as('seconds');
            let retColor = base.base;
            if (duration > 240) {
                retColor = base.darken4;
                dark = true;
                light = false;
            }
            else if (duration > 180) {
                retColor = base.darken3;
                dark = true;
                light = false;
            }
            else if (duration > 120) {
                retColor = base.darken2;
                dark = true;
                light = false;
            }
            else if (duration > 60) {
                retColor = base.darken1;
                dark = true;
                light = false;
            }
            return {
                color: retColor,
                dark,
                light,
            }
        },
        parseDuration(text) {
            const [minutes, seconds] = text.split(':');
            const duration = moment.duration(0);
            duration.add(parseInt(seconds), 'seconds');
            duration.add(parseInt(minutes), 'minutes');
            return duration;
        }
    },
    mounted() {
        this.$io.$on('dialerReport', this.handleDialerReport);
    },
    beforeDestroy() {
        this.$io.$off('dialerReport', this.handleDialerReport);
    },
}
</script>

<style lang="scss">
    #summary-sheet {
        position: relative;
        @media (min-width: 960px) {
            padding-left: 256px;
        }
    }

    .no-header-mobile {
        .v-data-table-header-mobile {
            display: none;
        }
    }
</style>