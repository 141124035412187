<template>
    <div>
        <label class="d-block mb-3 mt-2 mx-2">Manage Do Not Disturb Times</label>
        <v-calendar
            type="week"
            event-overlap-mode="stack"
            @click:interval="handleIntervalClick"
            @click:time="handleIntervalClick"
            @click:event="handleEventClick"
            :short-intervals="false"
            :short-weekdays="false"
            :events="events"
            ref="calendar"
        >
            <template v-slot:day-label-header>
                <span></span>
            </template>
        </v-calendar>
        <v-dialog v-model="show.form" max-width="500">
            <validation-observer slim ref="addobserver" v-slot="{ invalid }">
                <v-card color="panel">
                    <v-toolbar dense color="toolbar" class="remove-end-padding">
                        <v-toolbar-title>Add DND Time</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon @click="closeAddForm">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-divider color="white"></v-divider>
                    <v-card-text class="pa-3">
                        <validation-provider v-slot="{ errors }" name="Events" rules="required">
                            <v-select type="text" :items="eventTypes" v-model="payload.form.types" :loading="loading" :disabled="loading" :error-messages="errors" label="Events" required multiple chips deletable-chips></v-select>
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="Days" rules="required">
                            <v-select type="text" :items="dows" v-model="payload.form.dows" :loading="loading" :disabled="loading" :error-messages="errors" label="Days" required multiple chips deletable-chips></v-select>
                        </validation-provider>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" name="From" rules="required">
                                    <v-select type="text" :items="hours" v-model="payload.form.sh" :loading="loading" :disabled="loading" :error-messages="errors" label="From" required></v-select>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" name="To" rules="required">
                                    <v-select type="text" :items="endHours" v-model="payload.form.eh" :loading="loading" :disabled="loading" :error-messages="errors" label="To" required></v-select>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-end">
                        <v-btn color="primary" text :disabled="invalid" :loading="loading" @click="addDNDTime">
                        <span>Add DND Time</span>
                        <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
        <v-dialog v-model="show.edit" max-width="500">
            <validation-observer slim ref="editobserver" v-slot="{ invalid }">
                <v-card color="panel">
                    <v-toolbar dense color="toolbar" class="remove-end-padding">
                        <v-toolbar-title>Edit DND Time</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon @click="closeEditForm">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-divider color="white"></v-divider>
                    <v-card-text class="pa-3">
                        <validation-provider v-slot="{ errors }" name="Event" rules="required">
                            <v-select type="text" :items="eventTypes" v-model="payload.edit.type" :loading="loading" :disabled="loading" :error-messages="errors" readonly label="Event" required></v-select>
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="Day" rules="required">
                            <v-select type="text" :items="dows" v-model="payload.edit.dow" :loading="loading" :disabled="loading" :error-messages="errors" readonly label="Day" required></v-select>
                        </validation-provider>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" name="From" rules="required">
                                    <v-select type="text" :items="hours" v-model="payload.edit.sh" :loading="loading" :disabled="loading" :error-messages="errors" label="From" required></v-select>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" name="To" rules="required">
                                    <v-select type="text" :items="endHours" v-model="payload.edit.eh" :loading="loading" :disabled="loading" :error-messages="errors" label="To" required></v-select>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-end">
                        <v-btn color="error" text :loading="loading" @click="deleteDNDTime">
                            <span>Remove</span>
                            <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                        </v-btn>
                        <v-btn color="success" text :disabled="invalid" :loading="loading" @click="editDNDTime">
                            <span>Update</span>
                            <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import merge from 'lodash.merge';
export default {
    props: {
        report: {
            type: [Object, Array],
            required: true,
        },
        lead: {
            type: [Object, Array],
            required: true,
        },
        ftd: {
            type: [Object, Array],
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        show: {
            form: false,
            edit: false,
        },
        payload: {
            form: {
                types: [],
                dows: [],
                sh: 0,
                eh: 0,
            },
            edit: {
                type: null,
                dow: null,
                sh: null,
                eh: null,
                so: {},
                sk: null,
            },
        },
        u: 0,
    }),
    computed: {
        _report: {
            get() {
                return this.report;
            },
            set(val) {
                this.$emit('input:report', val);
                this.$emit('update:report', val);
                this.$emit('updated:report', val);
                this.$emit('change:report', val);
                this.$emit('changed:report', val);
            }
        },
        _lead: {
            get() {
                return this.lead;
            },
            set(val) {
                this.$emit('input:lead', val);
                this.$emit('update:lead', val);
                this.$emit('updated:report', val);
                this.$emit('change:report', val);
                this.$emit('changed:report', val);
            }
        },
        _ftd: {
            get() {
                return this.ftd;
            },
            set(val) {
                this.$emit('input:ftd', val);
                this.$emit('update:ftd', val);
                this.$emit('updated:report', val);
                this.$emit('change:report', val);
                this.$emit('changed:report', val);
            }
        },
        eventTypes() {
            return [
                {
                    value: 'hourly_report',
                    text: 'Hourly Report',
                },
                {
                    value: 'lead',
                    text: 'Lead Conversion',
                },
                {
                    value: 'ftd',
                    text: 'FTD Conversion',
                }
            ]
        },
        dows() {
            return [
                {
                    value: 0,
                    text: 'Sunday',
                },
                {
                    value: 1,
                    text: 'Monday',
                },
                {
                    value: 2,
                    text: 'Tuesday',
                },
                {
                    value: 3,
                    text: 'Wednesday',
                },
                {
                    value: 4,
                    text: 'Thursday',
                },
                {
                    value: 5,
                    text: 'Friday',
                },
                {
                    value: 6,
                    text: 'Saturday',
                },
            ]
        },
        hours() {
            const ret = [];
            let i = 0;
            while (i < 24) {
                const p = {
                    value: i,
                    text: `${i}:00`,
                }
                if (p.text.length < 5) {
                    p.text = `0${p.text}`;
                }
                ret.push(p);
                i ++;
            }
            return ret;
        },
        endHours() {
            const ret = [];
            let i = 0;
            while (i < 24) {
                const p = {
                    value: i,
                    text: `${i}:59`,
                }
                if (p.text.length < 5) {
                    p.text = `0${p.text}`;
                }
                ret.push(p);
                i ++;
            }
            return ret.filter((v) => {
                return (v.value >= this.payload.form.sh);
            });
        },
        events() {
            this.u;
            const ret = [];
            if (this._report.times) {
                for (let i = 0; i < this._report.times.length; i++) {
                    const to = this._report.times[i];
                    const smo = moment().day(to.dow).hour(to.start).startOf('hour');
                    const emo = moment().day(to.dow).hour(to.end).endOf('hour');
                    const p = {
                        name: 'Hourly Report',
                        start: smo.toDate(),
                        end: emo.toDate(),
                        color: 'primary',
                        timed: true,
                        so: to,
                        sk: '_report',
                    }
                    ret.push(p);
                }
            }
            if (this._lead.times) {
                for (let i = 0; i < this._lead.times.length; i++) {
                    const to = this._lead.times[i];
                    const smo = moment().day(to.dow).hour(to.start).startOf('hour');
                    const emo = moment().day(to.dow).hour(to.end).endOf('hour');
                    const p = {
                        name: 'Lead Conversions',
                        start: smo.toDate(),
                        end: emo.toDate(),
                        color: 'teal',
                        timed: true,
                        so: to,
                        sk: '_lead',
                    }
                    ret.push(p);
                }
            }
            if (this._ftd.times) {
                for (let i = 0; i < this._ftd.times.length; i++) {
                    const to = this._ftd.times[i];
                    const smo = moment().day(to.dow).hour(to.start).startOf('hour');
                    const emo = moment().day(to.dow).hour(to.end).endOf('hour');
                    const p = {
                        name: 'FTD Conversions',
                        start: smo.toDate(),
                        end: emo.toDate(),
                        color: 'success',
                        timed: true,
                        so: to,
                        sk: '_ftd',
                    }
                    ret.push(p);
                }
            }
            return ret;
        }
    },
    methods: {
        updateAll() {
            this.$emit('input', {
                report: this.report,
                lead: this.lead,
                ftd: this.ftd,
            })
            this.$emit('change', {
                dnc_hourly_report_times: this.report,
                dnc_lead_times: this.lead,
                dnc_ftd_times: this.ftd,
            })
            this.$nextTick(() => {
                if (this.$refs.calendar) {
                    this.$refs.calendar.checkChange();
                }
                this.u ++;
            })
        },
        handleIntervalClick(dto) {
            this.payload.form.dows.push(dto.weekday);
            this.payload.form.sh = dto.hour;
            this.payload.form.eh = dto.hour;
            this.show.form = true;
        },
        handleEventClick({nativeEvent, event}) {
            this.payload.edit.so = event.so;
            this.payload.edit.sk = event.sk;
            switch (event.sk) {
                case '_report':
                    this.payload.edit.type = 'hourly_report';
                    break;

                default:
                    this.payload.edit.type = event.sk.substr(1);
                    break;
            }
            this.payload.edit.dow = event.so.dow;
            this.payload.edit.sh = event.so.start;
            this.payload.edit.eh = event.so.end;
            this.show.edit = true;
            nativeEvent.stopPropagation();
        },
        closeAddForm() {
            this.show.form = false;
            this.payload.form.types = [];
            this.payload.form.dows = [];
            this.payload.form.sh = 0;
            this.payload.form.eh = 0;
            this.$refs.addobserver.reset();
        },
        closeEditForm() {
            this.show.edit = false;
            this.payload.edit.type = null;
            this.payload.edit.dow = null;
            this.payload.edit.sh = null;
            this.payload.edit.eh = null;
            this.payload.edit.so = {};
            this.payload.edit.sk = null;
            this.$refs.editobserver.reset();
        },
        async addDNDTime() {
            const valid = await this.$refs.addobserver.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.addobserver.errors);
                return false;
            }
            for (let i = 0; i < this.payload.form.types.length; i++) {
                const type = this.payload.form.types[i];
                const prop = ('hourly_report' == type) ? '_report' : `_${type}`;
                for (let ti = 0; ti < this.payload.form.dows.length; ti++) {
                    const dow = this.payload.form.dows[ti];
                    const p = {
                        dow,
                        start: this.payload.form.sh,
                        end: this.payload.form.eh,
                    }
                    if ('undefined' == typeof this[prop].times || !Array.isArray(this[prop].times)) {
                        this[prop].times = [];
                    }
                    this[prop].times.push(p);
                }
            }
            this.closeAddForm();
            this.afterUpdate();
        },
        async editDNDTime() {
            const valid = await this.$refs.editobserver.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.editobserver.errors);
                return false;
            }
            const ei = this.getEventItemIndex(this.payload.edit.sk, this.payload.edit.so);
            if (ei > -1) {
                const u = merge({}, this[this.payload.edit.sk].times[ei], {
                    start: this.payload.edit.sh,
                    end: this.payload.edit.eh,
                });
                this[this.payload.edit.sk].times.splice(ei, 1, u);
            }
            this.closeEditForm();
            this.afterUpdate();
        },
        async deleteDNDTime() {
            const ei = this.getEventItemIndex(this.payload.edit.sk, this.payload.edit.so);
            if (ei > -1) {
                this[this.payload.edit.sk].times.splice(ei, 1);
            }
            this.closeEditForm();
            this.afterUpdate();
        },
        afterUpdate() {
            this.$nextTick(() => {
                this.u ++;
                if (this.$refs.calendar) {
                    this.$refs.calendar.checkChange();
                }
                this.updateAll();
            })
        },
        getEventItemIndex(sk, so) {
            if ('undefined' == typeof this[sk].times || !Array.isArray(this[sk].times)) {
                return -1;
            }
            let i = this[sk].times.indexOf(so);
            if (-1 == i) {
                for (let si = 0; si < this[sk].times.length; si++) {
                    const e = this[sk].times[si];
                    if (e.dow == so.dow && e.start == so.start && e.end == so.end) {
                        i = si;
                        break;
                    }
                }
            }
            return i;
        }
    },
    mounted() {
        this.$on('input:report', this.updateAll)
        this.$on('input:lead', this.updateAll)
        this.$on('input:ftd', this.updateAll)
    },
    beforeDestroy() {
        this.$off('input:report', this.updateAll)
        this.$off('input:lead', this.updateAll)
        this.$off('input:ftd', this.updateAll)
    },
}
</script>